import React, { useState } from "react";
import '../styles/navBar-styles.css';
import UChicago from '../images/uchicago.svg';
import Vanderbilt from '../images/Vanderbilt.svg';
import Syracuse from '../images/syracuse.svg';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "gatsby"
import HamburgerMenu from './hamburger';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        <>

            <header>


                <nav role="navigation" aria-label="Main" >
                    <Container>

                        <Row>

                            <Col xs={12} md={6} >
                                <a className="hamburger-links" href="https://www.syracuse.edu/admissions-aid/" target="_blank" rel="noreferrer">
                                    <img src={Syracuse} alt="Syracuse Admissions" />
                                </a>
                                <a className="hamburger-links" href="https://www.vanderbilt.edu/admissions/" target="_blank" rel="noreferrer">
                                    <img src={Vanderbilt} alt="Vanderbilt Admissions" />
                                </a>
                                <a className="hamburger-links" href="https://collegeadmissions.uchicago.edu/" target="_blank" rel="noreferrer">
                                    <img src={UChicago} alt="UChicago Admissions" />
                                </a>
                            </Col>





                            <Col xs={12} md={6}>
                                

                                <div className="regular-menu">
                                    <Link to="/#about" className={`hamburger-links ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                        <h5 className='hamburger-text'>ABOUT</h5>
                                    </Link>
                                    <Link to="/#events" className={`hamburger-links ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                        <h5 className='hamburger-text'>DATES/LOCATIONS</h5>
                                    </Link>
                                    <Link to="/#contact" className={`hamburger-links ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                        <h5 className='hamburger-text'>CONTACT</h5>
                                    </Link>
                                </div>

                                <HamburgerMenu />
                            </Col>
                        </Row>

                    </Container>




                </nav>

            </header>

        </>
    );
}

export default Navbar;