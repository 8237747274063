import * as React from "react"
import "../styles/layout-styles.css"
import '../styles/global.css'
import Navbar from "./navBar"
import Footer from "./footer"

const Layout = ({ children }) => {

  return (
    <>
      <Navbar />
      <main className="main">
        {children}
      </main>
      <section id="contact">
        <Footer />
      </section>
    </>
  )
}

export default Layout
