import React, { useState } from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import '../styles/hamburger.css'; // Make sure to create this CSS file for styles

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container className="hamburger-container"><Row>
    <Col xs={12} className="hamburger-buns">
      <button className="hamburger-button" onClick={toggleMenu}>
        ☰
      </button>
      {isOpen && (
        <div className="menu">
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#events">Dates</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      )}
    </Col></Row></Container>
  );
};

export default HamburgerMenu;
