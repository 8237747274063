import React from "react";
import { Link } from "gatsby"
import '../styles/footer-styles.css'
import { Container, Row, Col } from 'react-bootstrap';
import UChicago from '../images/uchicago-shield.png';
import Vanderbilt from '../images/vanderbilt-1.png';
import Syracuse from '../images/syracuse-university.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <section id="contact">
            <footer className="footer">
                <Row>
                    {/* **************************** SYRACUSE SECTION ************************* */}
                    <Col xs={12} sm={3}><h3>CONTACT</h3></Col>
                    <Col xs={12} sm={3} >
                        <Link to="https://www.syracuse.edu/admissions-aid/application-process/undergraduate/" target="_blank" rel="noreferrer"><img className="footer-icon" src={Syracuse} alt="Syracuse Admissions" /></Link>
                        <h3>Syracuse</h3>
                        <p><a href="https://www.syracuse.edu/admissions-aid/application-process/undergraduate/" target="_blank">Admissions Site <FontAwesomeIcon icon={faChevronRight} /></a><br />
                        <a href="https://www.syracuse.edu/admissions-aid/application-process/undergraduate/contact/request-information/" target="_blank">Join Mailing List  <FontAwesomeIcon icon={faChevronRight} /></a></p>
                    </Col>
                    {/* ******************************************************** VANDERBILT SECTION ******************************************************** */}
                    <Col xs={12} sm={3}>
                        <Link to="https://admissions.vanderbilt.edu/" target="_blank" rel="noreferrer"><img className="footer-icon" src={Vanderbilt} alt="Vanderbilt Admissions" /></Link>
                        <h3>Vanderbilt</h3>
                        <p><a href="https://admissions.vanderbilt.edu/" target="_blank" >Admissions Site <FontAwesomeIcon icon={faChevronRight} /></a> <br />
                        <a href="https://admissions.vanderbilt.edu/request/" target="_blank">Join Mailing List  <FontAwesomeIcon icon={faChevronRight} /></a></p>
                    </Col>
                    {/* ******************************************************** UCHICAGO SECTION ******************************************************** */}
                    <Col xs={12} sm={3}>
                        <Link to="https://collegeadmissions.uchicago.edu/" target="_blank" rel="noreferrer"><img className="footer-icon" src={UChicago} alt="UChicago Admissions" /></Link>
                        <h3>UChicago</h3>
                        <p><a href="https://collegeadmissions.uchicago.edu/" target="_blank">Admissions Site <FontAwesomeIcon icon={faChevronRight} /></a><br />
                        <a href="https://prospects.uchicago.edu/register/?id=43a61f54550842e3a7539edb90d99402" target="_blank">Join Mailing List  <FontAwesomeIcon icon={faChevronRight} /></a></p>
                    </Col>
                </Row>
            </footer>
        </section>
    );
}

export default Footer;
